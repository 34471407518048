@tailwind components;
@tailwind utilities;

@layer components {
  .AlertBoxDiv{
    @apply z-[100] h-screen w-screen bg-black bg-opacity-20 fixed top-0 left-0 flex items-center justify-center
  }

  .AlertBoxDivContent {
    @apply max-w-[600px] w-[95%]  bg-white rounded-xl
  }

  .AlertBoxDivTitle{
    @apply w-full bg-defaultColor min-h-[45px] rounded-tl-xl rounded-tr-xl text-white font-bold align-middle flex items-center text-lg pl-3
  }

  .AlertBoxDivTitle i{
    @apply mr-2
  }

  .AlertBoxDivTitle svg{
    @apply mr-2
  }


  .AlertBoxDivHtml{
    @apply w-full bg-white min-h-[100px] pt-2 pl-3 pr-3 max-h-[60vh] overflow-y-auto
  }

  .AlertBoxDivCtrl{
    @apply w-full min-h-[50px]  border-t border-dotted rounded-bl-xl rounded-br-xl pl-2 pr-2 flex items-center justify-end
  }

  .AlertBoxDivCtrl > Button{
    @apply h-[85%] w-[60px] p-2 m-1 bg-defaultColor rounded-md text-white font-bold hover:shadow-sm hover:shadow-defaultColor
  }
}