@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* 預設所有元素 ==>relative */
  * {
    @apply relative;
  }
  /* 主體背景色、高度 */
  body {
    @apply min-h-[100vh] md:h-[100vh];
  }

  /* 內容區塊樣式 */
  .main {
    @apply z-10 md:h-[90%] w-full flex flex-col md:flex-row  md:overflow-hidden bg-lightColor;
  }

  .main > div {
    @apply md:min-h-full md:h-full;
  }

  /* Footer區塊樣式 */
  .footer {
    @apply z-10 text-base break-all md:text-lg 2xl:text-2xl lg:text-3xl h-[5%] md:h-[10%] w-full bg-defaultColor font-bold text-white flex justify-center items-center;
  }
  /* Logo樣式 */
  .logo {
    @apply md:block absolute top-[50%] translate-y-[-50%] left-2 h-[70%] sm:h-[80%];
  }

  .Copyright {
    @apply absolute bottom-0 left-[50%] translate-x-[-50%]  hidden lg:block lg:text-xs text-black;
  }
  /* 側邊選單容器 */
  .sidebarContainer {
    @apply z-20 md:z-0 md:hidden absolute top-0 left-0 w-full h-full bg-black bg-opacity-20;
  }

  /* 側邊選單開關按鈕 */
  .sidebarBtn {
    @apply z-50
          md:z-0 md:hidden
          text-defaultColor
          font-black
          text-center text-5xl
          tracking-[0.4em]
          flex;
  }

  /* 側邊選單標題 */
  .sidebarHeader {
    @apply w-full
    h-[40px]
    bg-defaultColor
    text-center 
    text-sm md:text-lg lg:text-2xl
    font-bold
    break-all
    text-white
    flex
    items-center
    justify-center;
  }

  /* 側邊選單功能按鈕 */
  .sildbarMenuBtn {
    @apply w-[90%] h-[50px] rounded-full mt-2 mb-2 text-center border border-solid border-black align-middle flex justify-center items-center font-bold text-base;
  }

  .sildbarMenu {
    @apply absolute left-2 h-[40px] w-[40px] rounded-full bg-lightColor text-defaultColor flex  justify-center items-center;
  }

  /* 主選單樣式 */
  .MenuList {
    @apply p-[3%] md:p-[5%] w-full h-[50%] md:h-full lg:h-full grid grid-cols-3 grid-rows-none md:grid-rows-4 overflow-y-auto;
  }

  /* 主選單Div樣式 */
  .MenuList > div {
    @apply md:flex justify-center items-center;
  }

  /* 主選單使用者容器樣式 */
  .MenuListchild1 {
    @apply bg-white/50
        border-2 border-borderColor rounded-3xl col-span-2 shadow-lg hidden md:flex flex-col;
  }

  /* 主選單按鈕樣式 */
  .MenuBtn {
    @apply flex justify-center items-center m-1 md:m-0;
  }

  .MenuList .MenuBtn > div {
    @apply shadow-lg border-2 border-defaultColor bg-white w-[120px] h-[120px]  md:w-[80%] md:h-[90%] rounded-3xl flex justify-center items-center hover:shadow-2xl;
  }

  .MenuList .MenuBtn a {
    @apply text-defaultColor text-center  w-full  h-full  flex flex-col justify-center items-center p-2 lg:p-4;
  }

  .MenuList .MenuBtn span {
    @apply mt-1 text-black text-xs lg:text-sm xl:text-xl 2xl:text-lg font-bold;
  }
  .MenuList .MenuBtn svg {
    @apply h-[35%];
  }

  .searchBtn {
    @apply absolute right-0 top-0 bg-defaultColor font-bold hover:shadow-2xl text-white h-full w-[25%] rounded-full text-lg;
  }

  .SearchBar {
    @apply w-full
    h-full
    pl-4
    pr-[25%]
    text-black
    rounded-full;
  }

  .UserBox {
    @apply p-4 hidden md:block;
  }

  .UserBox .NameBox {
    @apply w-full h-[40%] text-center text-base lg:text-2xl font-bold flex justify-center items-center;
  }

  .UserBox .BtnContainer {
    @apply w-full
    h-[60%]
    rounded-xl
    flex
    opacity-100
    justify-evenly
    items-center
    bg-lightColor
    border-2 border-defaultColor;
  }

  .UserBox .Btn {
    @apply hover:shadow-md rounded-lg bg-white h-[85%] md:text-lg  flex justify-center items-center;
  }

  /* 手機版面(標題、公告搜尋輸入框)樣式 */
  .MobileSearchBar {
    @apply flex md:hidden flex-col items-center pt-4   h-[50%] text-[white] text-center font-black  text-5xl   drop-shadow-lg;
  }

  /* 公告區塊Size */
  .NewsBox {
    @apply w-full md:w-[60%] min-h-[70%] h-[50%] md:h-full;
  }

  /* 表格Size 與內容文字大小 */
  .table {
    @apply w-full text-sm lg:text-lg;
  }

  /* 表格文字置中、自動換行 */
  .table tr {
    @apply text-center break-all min-h-full;
  }
  /* 標題背景色、粗體、文字垂直置中 */
  .table th {
    @apply align-middle bg-defaultColor  font-bold;
  }

  /* 內容背景色、文字垂直置中 */
  .table td {
    @apply align-middle  text-black border-r border-gray-300 border-solid;
  }

  .table td:nth-child(1) {
    @apply min-h-[50px] flex justify-center items-center;
  }

  .table td:nth-last-child(1) {
    @apply border-none;
  }
  /* 奇數列背景色 */
  .table tr:nth-child(odd) {
    @apply bg-slate-200;
  }
  /* 偶數列背景色 */
  .table tr:nth-child(even) {
    @apply bg-white;
  }

  /* 表格四邊圓角 */
  .table tr:nth-last-child(1) > td:nth-last-child(1) {
    @apply rounded-br-lg lg:rounded-br-3xl;
  }
  .table tr:nth-last-child(1) > td:nth-child(1) {
    @apply rounded-bl-lg lg:rounded-bl-3xl;
  }

  .table tr:nth-child(1) > th:nth-last-child(1) {
    @apply rounded-tr-lg lg:rounded-tr-3xl;
  }
  .table tr:nth-child(1) > th:nth-child(1) {
    @apply rounded-tl-lg lg:rounded-tl-3xl;
  }

  /* 網頁捲軸【寬度】 */
  ::-webkit-scrollbar {
    @apply w-[5px] ml-2;
  }

  /* 網頁捲軸【背景】顏色 */
  ::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }

  /* 網頁捲軸【把手】顏色 */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
  }

  /* 網頁捲軸【滑過時】把手的顏色 */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700;
  }

  /* 選單按鈕捲軸不顯示 */
  .MenuList::-webkit-scrollbar {
    @apply w-0;
  }

  /* 公告容器 */
  .NewsContainer {
    @apply z-[100] min-h-full  md:h-full w-full absolute top-0 left-0 bg-black bg-opacity-50 hidden justify-center items-center pt-8 pb-8;
  }

  /* 公告主體 */
  .NewsBody {
    @apply z-[200] text-defaultColor bg-white rounded-3xl p-5 md:p-8 md:overflow-y-auto w-[95%] md:h-[80%] md:w-[60%];
  }

  /* 公告標題 */
  .NewsTitle {
    @apply text-base lg:text-2xl font-bold;
  }

  /* 公告單位 */
  .NewsUnit {
    @apply w-full text-right;
  }

  /* 公告內容 */
  .NewsContent {
    @apply mt-2 min-h-[300px] md:min-h-[75%];
  }

  /* 公告時間 */
  .NewsTime {
    @apply w-full text-right mt-1;
  }

  /* 公告底板(點擊時關閉公告瀏覽視窗) */
  .NewsBottom {
    @apply absolute top-0 left-0 w-full h-full;
  }

  /* 公告關閉按鈕(點擊時關閉公告瀏覽視窗) */
  .NewsCloseBtn {
    @apply w-[50px] h-[50px] bg-gray-300 fixed top-2 right-4 z-[210] opacity-60 rounded-full md:absolute flex justify-center items-center;
  }

  /* 公告狀態 */
  .NewsStatus {
    @apply rounded-3xl  text-white text-xs w-[50%] lg:w-[50px];
  }

  /* 登入視窗 */
  .LoginContainer {
    @apply z-[80] min-h-full h-[100vh] md:h-full w-full absolute top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center pt-8 pb-8 overflow-y-auto;
  }

  /* 登入視窗底板(點擊時關閉登入視窗) */
  .LoginBottom {
    @apply absolute top-0 left-0 w-full h-full;
  }

  .LoginBody {
    @apply min-h-[580px] md:min-h-0 md:max-h-[580px] w-[85%] md:w-[400px] bg-defaultColor  md:h-[100%] rounded-3xl pt-4 flex flex-col items-center  justify-center;
  }

  .LoginImgBox {
    @apply w-full flex justify-center items-center;
  }

  .LoginSelect {
    @apply w-full h-[40px] mt-3 flex justify-center items-center;
  }

  .LoginSelect > Button {
    @apply m-4
    text-lg
    font-bold
    text-white
     underline-offset-4;
  }

  .LoginInputBox {
    @apply w-[60%] h-[50px] mt-2 mb-4;
  }

  .LoginInputBox > input {
    @apply w-full h-full rounded-full pl-4  pr-4 text-left;
  }

  .LoginSendBtn {
    @apply w-[35%]
    rounded-2xl
    bg-slate-200
    text-center
    flex
    justify-center
    items-center
    font-bold
    text-2xl
    mt-2;
  }
}

/* select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
} */
[v-cloak] {
  display: none;
}